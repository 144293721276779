import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useCallback,
  useRef,
} from 'react';

import {
  ILoja,
  IProdFornUN,
  ISelect,
  ITipoItem,
  tipoPisCofinsEntrada,
  tipoPisCofinsSaida,
} from './types';
import { LojaContext } from '~/context/loja';

import { ISimilar, IDepartamento } from '~/pages/Produto/types';
import api from '~/services/api';
import {
  FormAssociado,
  formAssociadoBlank,
  FormGtin,
  formGtinBlank,
  FormProduto,
  formProdutoBlank,
  formProdutoBlankNew,
  FormProdutoLoja,
  formProdutoLojaBlank,
  Select,

  // fornecedor
  FormProdutoFornecedor,
  formProdutoFornecedorBlank,
  FormProdutoFornecedorUN,
  formProdutoFornecedorUNBlank,
  ProdutoFornecedor,
} from './protocols';
import {
  Control,
  FieldValues,
  FormState,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { schema } from './Validations';
import { MapeamentoValores } from './protocols';
import { useQueryClient } from 'react-query';

type ProdutoContextData = {
  formProduto: FormProduto;
  changeFormProduto: (value: FormProduto) => void;
  formProdutoLoja: FormProdutoLoja;
  changeFormProdutoLoja: (value: FormProdutoLoja) => void;
  isUpdate: boolean;
  toggleIsUpdate: (value: boolean) => void;
  isShowSearch: boolean;
  toggleIsShowSearch: (value: boolean) => void;
  handleClearForms: () => void;
  initInicializado: boolean;
  toggleInitInicitalizado: (value: boolean) => void;
  handleSelectedLoja: (loja: number) => void;
  selectedLoja: number;
  handleDuplicateForm: () => void;
  mainLoja: ILoja;
  changeMainLoja: (reg: ILoja) => void;
  formAssociado: FormAssociado;
  changeFormAssociado: (value: FormAssociado) => void;
  tipoGtin: number;
  setTipoGtin: (value: number) => void;
  formGtin: FormGtin;
  setFormGtin: (formGtin: FormGtin) => void;
  produtoFornecedoresUns: IProdFornUN[];
  setProdutoFornecedorUns: (prodFornUN: IProdFornUN[]) => void;
  selectedCodFornecedor: number | undefined;
  setSelectedCodFornecedor: (value: number | undefined) => void;
  filtro: Select | null;
  setFiltro: (value: Select | null) => void;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  reset: UseFormReset<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  formState: FormState<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  clearFields: boolean;
  handleResetClearField: () => void;
  handleChangeIsDuplicate: (value: boolean) => void;
  isDuplicate: boolean;
  handleChangeIsDuplicateForUpdate: (value: boolean) => void;
  isDuplicateForUpdate: boolean;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  setTributacoesEntrada: (val: Select[]) => void;
  setTributacoesSaida: (val: Select[]) => void;
  tributacoesEntrada: Select[];
  tributacoesSaida: Select[];
  ncmIsFromTheProduct: boolean;
  setNcmIsFromTheProduct: (val: boolean) => void;
  handleChangeValoresCst: (tipo: keyof MapeamentoValores) => void;
  desabilitarBuscaModal: () => boolean;
  handleChangeEnabledModal: () => void;

  enableModalSped: boolean;
  setEnableModalSped: (val: boolean) => void;
  refSaida: any;
  optionsEntradaPisCofins: Select[];
  setOptionsEntradaPisCofins: (val: Select[]) => void;
  handleTipoIPI: (value: number | string) => void;
  ipiHabilitar: boolean;
  setIpiHabilitar: (val: boolean) => void;
  tabGeral: string;
  setTabGeral: (val: string | null) => void;
  watchFlgVedado: boolean;
  resetFormEmbalagem: () => void;
  tab: string;
  setTab: (val: string | null) => void;

  // aba fornecedor
  initFormFornecedor: boolean;
  setInitFormFornecedor: (val: boolean) => void;
  initFormFornecedorUn: boolean;
  setInitFormFornecedorUn: (val: boolean) => void;
  produtoFornecedores: ProdutoFornecedor[];
  setProdutoFornecedores: (val: ProdutoFornecedor[]) => void;
  formProdFornUN: FormProdutoFornecedorUN;
  setFormProdFornUN: (val: FormProdutoFornecedorUN) => void;
  formProdutoFornecedor: FormProdutoFornecedor;
  setFormProdutoFornecedor: (val: FormProdutoFornecedor) => void;
  idProdUnid: any;
  setIdProdUnid: any;
  handleClearProdFornUN: () => void;
  handleClearProdutoFornecedor: () => void;

  associadosPai: any[];
  setAssociadosPai: (val: any[]) => void;
  associadosFilho: any;
  setAssociadosFilho: (val: any[]) => void;

  watchTipoAssociado: number;
  similares: Select[];
  setSimilares: (val: Select[]) => void;
  watchFlgNaoPisCofins: boolean;
  hasErroTipoIncidente: boolean;
  setHasErroTipoIncidente: (val: boolean) => void;
  removeEditNaoPisCofinsCache: () => void;
  flgFabricante: boolean;
  setFlgFabricante: (val: boolean) => void;
  disabledField: boolean;
  setDisabledField: (val: boolean) => void;
  setFormProdutoFornecedorEmbalagem: (val: FormProdutoFornecedor) => void;
  formProdutoFornecedorEmbalagem: FormProdutoFornecedor;

  tipoItem: any;
  setTipoItem: (val: any) => void;
  idContabeis: ISelect[];
  setIdContabeis: (val: ISelect[]) => void;

  handleTipoItemAndIdCtb: () => void;

  departamentos: Select[];
  setDepartamentos: (val: Select[]) => void;

  getDepartamentos: () => void;
  getIdContabeis: () => void;
  getTipoItem: () => void;
  getUnidades: () => void;
  unidadesOptions: ISelect[];
  resetSimilar: boolean;
  setResetSimilar: (val: boolean) => void;
};

export const ProdutoContext = createContext({} as ProdutoContextData);

interface ProdutoContextProviderProps {
  children: ReactNode;
}

export function ProdutoContextProvider({
  children,
}: ProdutoContextProviderProps): JSX.Element {
  const { loja } = useContext(LojaContext);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isShowSearch, setIsShowSearch] = useState(true);
  const [initInicializado, setInitInicializado] = useState(false);
  const [selectedLoja, setSelectedLoja] = useState(0);
  const [isDuplicateForUpdate, setIsDuplicateForUpdate] =
    useState<boolean>(false);
  const [filtro, setFiltro] = useState<Select | null>(null);
  const [clearFields, setClearFields] = useState(false);

  const [tipoItem, setTipoItem] = useState<any>('');
  const [departamentos, setDepartamentos] = useState<Select[]>([]);
  const [idContabeis, setIdContabeis] = useState<ISelect[]>([]);
  const [unidadesOptions, setUnidadesOptions] = useState<ISelect[]>([]);

  const [formProduto, setFormProduto] = useState<FormProduto>(formProdutoBlank);
  const [produtoFornecedoresUns, setProdutoFornecedorUns] = useState<
    IProdFornUN[]
  >([]);
  const MySwal = withReactContent(Swal);

  const [selectedCodFornecedor, setSelectedCodFornecedor] = useState<
    number | undefined
  >(undefined);

  const [formProdutoLoja, setFormProdutoLoja] =
    useState<FormProdutoLoja>(formProdutoLojaBlank);

  const [formAssociado, setFormAssociado] =
    useState<FormAssociado>(formAssociadoBlank);

  const [tipoGtin, setTipoGtin] = useState(13);
  const [formGtin, setFormGtin] = useState<FormGtin>(formGtinBlank);
  const [tabGeral, setTabGeral] = useState<any>('icms');
  const [tab, setTab] = useState<any>('geral');

  // aba fornecedor
  const [initFormFornecedor, setInitFormFornecedor] = useState(false);
  const [initFormFornecedorUn, setInitFormFornecedorUn] = useState(false);
  const [produtoFornecedores, setProdutoFornecedores] = useState<
    ProdutoFornecedor[]
  >([]);
  const [flgFabricante, setFlgFabricante] = useState<boolean>(false);
  const [disabledField, setDisabledField] = useState<boolean>(false);

  const [idProdUnid, setIdProdUnid] = useState<any>(undefined);

  const [formProdutoFornecedor, setFormProdutoFornecedor] =
    useState<FormProdutoFornecedor>(formProdutoFornecedorBlank);
  const [formProdutoFornecedorEmbalagem, setFormProdutoFornecedorEmbalagem] =
    useState<FormProdutoFornecedor>(formProdutoFornecedorBlank);

  const [formProdFornUN, setFormProdFornUN] = useState<FormProdutoFornecedorUN>(
    formProdutoFornecedorUNBlank,
  );

  // fiscal
  const [tributacoesEntrada, setTributacoesEntrada] = useState<Select[]>([]);
  const [tributacoesSaida, setTributacoesSaida] = useState<Select[]>([]);
  const [ncmIsFromTheProduct, setNcmIsFromTheProduct] = useState<boolean>(true);

  // PIS/COFINS
  const refSaida = useRef<HTMLDivElement | null>(null);
  const [optionsEntradaPisCofins, setOptionsEntradaPisCofins] = useState<
    Select[]
  >([]);
  const [enableModalSped, setEnableModalSped] = useState<boolean>(false);
  const [hasErroTipoIncidente, setHasErroTipoIncidente] =
    useState<boolean>(false);

  // IPI
  const [ipiHabilitar, setIpiHabilitar] = useState<boolean>(true);
  const [associadosPai, setAssociadosPai] = useState<any[]>([]);
  const [associadosFilho, setAssociadosFilho] = useState<any[]>([]);

  const [controlaLimpaForm, setControlaLimpaForm] = useState(false);
  const [resetSimilar, setResetSimilar] = useState(false);
  const [mainLoja, setMainLoja] = useState<ILoja>({
    des_uf: loja.des_uf,
    cod_loja: loja.cod_loja,
    des_fantasia: loja.des_fantasia,
    des_cidade: loja.des_cidade,
    cst_pis_cof_ent_aliq_zero: loja.cst_pis_cof_ent_aliq_zero,
    cst_pis_cof_ent_monof: loja.cst_pis_cof_ent_monof,
    cst_pis_cof_ent_subst: loja.cst_pis_cof_ent_subst,
    cst_pis_cof_ent_imune: loja.cst_pis_cof_ent_imune,
    cst_pis_cof_ent_susp: loja.cst_pis_cof_ent_susp,
    cst_pis_cof_entrada: loja.cst_pis_cof_entrada,
    cst_pis_cof_saida_aliq_zero: loja.cst_pis_cof_saida_aliq_zero,
    cst_pis_cof_saida_monof: loja.cst_pis_cof_saida_monof,
    cst_pis_cof_saida_subst: loja.cst_pis_cof_saida_subst,
    cst_pis_cof_saida_imune: loja.cst_pis_cof_saida_imune,
    cst_pis_cof_saida_susp: loja.cst_pis_cof_saida_susp,
    cst_pis_cof_saida: loja.cst_pis_cof_saida,
  });

  const [similares, setSimilares] = useState<Select[]>([]);

  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    setValue,
    formState,
    setError,
    clearErrors,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit',
  });

  const queryClient = useQueryClient();

  const watchFlgVedado = watch('flg_veda_cred');
  const watchTipoAssociado = watch('flg_veda_cred');
  const watchFlgNaoPisCofins = watch('flg_nao_pis_cofins');
  const delay = (ms: number): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));

  function handleSelectedLoja(codLoja: number) {
    setSelectedLoja(codLoja);
  }

  function changeMainLoja(reg: ILoja) {
    setMainLoja({
      des_uf: reg.des_uf,
      cod_loja: reg.cod_loja,
      des_fantasia: reg.des_fantasia,
      des_cidade: reg.des_cidade,
      cst_pis_cof_ent_aliq_zero: reg.cst_pis_cof_ent_aliq_zero,
      cst_pis_cof_ent_monof: reg.cst_pis_cof_ent_monof,
      cst_pis_cof_ent_subst: reg.cst_pis_cof_ent_subst,
      cst_pis_cof_ent_imune: reg.cst_pis_cof_ent_imune,
      cst_pis_cof_ent_susp: reg.cst_pis_cof_ent_susp,
      cst_pis_cof_entrada: reg.cst_pis_cof_entrada,
      cst_pis_cof_saida_aliq_zero: reg.cst_pis_cof_saida_aliq_zero,
      cst_pis_cof_saida_monof: reg.cst_pis_cof_saida_monof,
      cst_pis_cof_saida_subst: reg.cst_pis_cof_saida_subst,
      cst_pis_cof_saida_imune: reg.cst_pis_cof_saida_imune,
      cst_pis_cof_saida_susp: reg.cst_pis_cof_saida_susp,
      cst_pis_cof_saida: reg.cst_pis_cof_saida,
    });
  }

  // limpa aba fornecedor
  function handleClearProdFornUN() {
    setIdProdUnid(undefined);
    setInitFormFornecedorUn(false);
    setFormProdFornUN(formProdutoFornecedorUNBlank);
    resetFormEmbalagem();
  }

  const handleClearProdutoFornecedor = useCallback(() => {
    const { ref } = formProdutoFornecedor.cod_fornecedor;
    if (ref.current) {
      ref.current.handleClearFornecedor();
    }

    setDisabledField(false);
    setValue('busca_parceiro', {
      label: '',
      value: undefined,
    });

    clearErrors('busca_parceiro');
    setFlgFabricante(false);
    setInitFormFornecedor(false);
    setResetSimilar(false);
    setSelectedCodFornecedor(undefined);
    setFormProdutoFornecedor(formProdutoFornecedorBlank);
    setFormProdutoFornecedorEmbalagem(formProdutoFornecedorBlank);
  }, [formProdutoFornecedor.cod_fornecedor]);

  const handleResetClearField = useCallback(() => {
    setClearFields(false);
    setResetSimilar(false);
    setSelectedCodFornecedor(undefined);
  }, []);

  const resetValuesForm = useCallback(() => {
    setValue('flg_veda_cred', false);
    setValue('des_produto', formProdutoBlankNew.des_produto);
    setValue('des_reduzida', formProdutoBlankNew.des_reduzida);
    setValue('cod_trib_entrada', formProdutoBlankNew.cod_trib_entrada);
    setValue('per_veda_cred_lim', formProdutoBlankNew.per_veda_cred_lim);
    setValue('cod_tributacao', formProdutoBlankNew.cod_tributacao);
    setValue('tipo_nao_pis_cofins', formProdutoBlankNew.tipo_nao_pis_cofins);
    setValue('ipi_tipo', formProdutoBlankNew.ipi_tipo);
    setValue('ipi_val', formProdutoBlankNew.ipi_val);
    setValue('des_unidade_compra', formProdutoBlankNew.des_unidade_compra);
    setValue('qtd_embalagem_compra', formProdutoBlankNew.qtd_embalagem_compra);
    setValue('val_peso', formProdutoBlankNew.val_peso);
    setValue('des_unidade_venda', formProdutoBlankNew.des_unidade_venda);
    setValue('qtd_embalagem_venda', formProdutoBlankNew.qtd_embalagem_venda);
    setValue('val_peso_emb', formProdutoBlankNew.val_peso_emb);
    setValue('val_peso_bruto', formProdutoBlankNew.val_peso_bruto);
    setValue('qtd_divisor_preco', formProdutoBlankNew.qtd_divisor_preco);
    setValue(
      'des_unidade_medida_preco',
      formProdutoBlankNew.des_unidade_medida_preco,
    );
    setValue('cod_similar', {
      label: 'Nenhum Similar Selecionado',
      value: undefined,
    });
    setValue('cod_marca', {
      label: 'Nenhuma Marca Selecionado',
      value: undefined,
    });
    setTimeout(() => {
      setValue('cod_ncm', {
        label: '',
        value: null,
      });
    }, 10);
    setValue('tecla_balanca', formProdutoBlankNew.tecla_balanca);
    setValue('cod_receita', formProdutoBlankNew.cod_receita);
    setValue('cod_info_nutricional', formProdutoBlankNew.cod_info_nutricional);
    setValue('cod_tara', formProdutoBlankNew.cod_tara);
    setValue('tipo_multiplicacao', formProdutoBlankNew.tipo_multiplicacao);
    setValue('qtd_etiqueta', formProdutoBlankNew.qtd_etiqueta);
    setValue('tipo_movimentacao', formProdutoBlankNew.tipo_movimentacao);
    setValue('per_perda', formProdutoBlankNew.per_perda);
    setValue('num_lote_rast', formProdutoBlankNew.num_lote_rast);
    setValue('qtd_lote_rast', formProdutoBlankNew.qtd_lote_rast);
    setValue('cod_agreg_rast', formProdutoBlankNew.cod_agreg_rast);
    setValue('des_tab_sped', formProdutoBlankNew.des_tab_sped);
    setValue('cod_tab_sped', formProdutoBlankNew.cod_tab_sped);
    setValue('des_sped', formProdutoBlankNew.des_sped);
    setValue('cod_sped', formProdutoBlankNew.cod_sped);
    setValue('des_referencia', '');
    setValue('des_unidade_compra', {
      value: 'UN',
      label: 'UN',
    });
    setValue('associados_pai', []);
    setValue('associados_filho', []);
    setValue('tipo_associado', 0);

    setAssociadosPai([]);
    setAssociadosFilho([]);

    setValue('qtd_embalagem_compra', 1);
    setValue('entrada_pis_cofins', { value: null, label: '= Saída' });
    setValue('cod_departamento', {
      value: null,
      label: 'Selecione o Departamento',
    });
    setValue('cod_tipo_item', {
      value: null,
      label: '- Selecione a Categoria -',
    });
    setValue('cod_id_ctb', {
      value: null,
      label: '- Selecione um ID Contábil -',
    });
    setValue('id_contabeis_options', []);
    setValue('tipo_itens', []);
  }, [setValue]);

  const resetFormEmbalagem = useCallback(() => {
    setValue('des_referencia', '');
    setValue('des_unidade_compra_fornecedor', null);
    setTimeout(() => {
      setValue('des_unidade_compra_fornecedor', {
        value: 'UN',
        label: 'UN',
      });
    }, 50);

    setValue('qtd_embalagem_compra_fornecedor', 1);
    setResetSimilar(false);
  }, [setValue]);

  const handleClearForms = useCallback(() => {
    const cod_produto = getValues('cod_produto');
    reset();
    setValue('cod_produto', cod_produto);

    setTabGeral('icms');
    const refVasilhame = formProdutoLoja.cod_vasilhame.ref;
    const refAssociado = formAssociado.cod_associado.ref;
    const refNcm = formProdutoLoja.cod_ncm.ref;

    if (refVasilhame.current) {
      refVasilhame.current.handleClearVasilhame();
    }
    if (refAssociado.current) {
      refAssociado.current.handleClearProduto();
    }

    if (refNcm.current) {
      refNcm.current.handleClearNcm();
    }
    setProdutoFornecedorUns([]);
    setFiltro(null);
    setInitInicializado(false);
    setFormAssociado(formAssociadoBlank);
    setFormProduto(formProdutoBlank);
    setFormProdutoLoja(formProdutoLojaBlank);
    setTipoGtin(13);
    setFormGtin(formGtinBlank);
    setIsDuplicate(false);
    setSelectedCodFornecedor(undefined);
    setNcmIsFromTheProduct(true);
    setControlaLimpaForm(false);
    handleClearProdutoFornecedor();
    resetFormEmbalagem();
    // resetField('cod_trib_entrada');
    // resetField('cod_tributacao');
    setClearFields(true);
    setTimeout(() => {
      resetValuesForm();
    }, 10);
  }, [
    formAssociado.cod_associado.ref,
    formProdutoLoja.cod_ncm.ref,
    formProdutoLoja.cod_vasilhame.ref,
    reset,
    resetFormEmbalagem,
    resetValuesForm,
    getValues,
    handleClearProdutoFornecedor,
    setValue,
  ]);

  const handleTipoItemAndIdCtb = () => {
    const codDepartamento: any = getValues('cod_departamento');
    if (codDepartamento) {
      if (tipoItem.length > 0) {
        setValue(
          'cod_tipo_item',
          tipoItem.find(
            (item: any) => item.value === codDepartamento.cod_tipo_item,
          ),
        );
      }

      if (idContabeis.length > 0) {
        const codIdCtbSelected = idContabeis.find(
          (item: any) => item.value === codDepartamento.cod_id_ctb,
        );

        setValue('cod_id_ctb', codIdCtbSelected || { value: null, label: '' });
      }
    }
  };

  async function getDepartamentos() {
    const res = await api.get('/produto/departamentos');
    const { data, success } = res.data;
    if (success) {
      const select = data.map((item: IDepartamento) => {
        return {
          value: item.cod_departamento,
          label: `${item.cod_departamento} - ${item.descricao}`,
          cod_tipo_item: item.cod_tipo_item,
          des_tipo_item: item.des_tipo_item,
          cod_id_ctb: item.cod_id_ctb,
        };
      });

      setDepartamentos(select);

      const { value: codDepartamento } = formProduto.cod_departamento.value;
      const selectedDepartamento = select.find(
        (item: Select) => item.value === codDepartamento,
      );
      if (selectedDepartamento) {
        setValue('cod_departamento', selectedDepartamento);
      }
    }
  }

  const handleResetForms = useCallback(() => {
    setFormProduto({
      ...formProduto,
      Fornecedores: formProdutoBlank.Fornecedores,
      cod_produto: formProdutoBlank.cod_produto,
      cod_gtin_principal: formProdutoBlank.cod_gtin_principal,
      Gtins: formProdutoBlank.Gtins,
      Embalagens: formProdutoBlank.Embalagens,
    });

    setFormProdutoFornecedor({
      ...formProdutoFornecedor,
      flg_fabricante: {
        ...formProdutoFornecedor.flg_fabricante,
        value: false,
      },
    });

    setSelectedCodFornecedor(undefined);
    setInitInicializado(false);
    setControlaLimpaForm(false);
    setValue('fornecedores', []);

    const { ref } = formProdutoFornecedor.cod_fornecedor;
    if (ref.current) {
      ref.current.handleClearFornecedor();
    }
  }, [formProduto, formProdutoFornecedor, setValue]);

  const handleSimilar = useCallback(async () => {
    const cod_similar = getValues('cod_similar');
    if (cod_similar.value) {
      await MySwal.fire({
        title: 'Confirmação',
        text: 'Produto a ser duplicado possui o campo similar relacionado. Deseja também duplicá-lo?',
        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (!result.isConfirmed) {
          setValue('cod_similar', {
            label: 'Nenhum Similar Selecionado',
            value: undefined,
          });
          setResetSimilar(true);
          setInitInicializado(false);
        }
      });
    }
  }, [MySwal, getValues, setValue]);

  async function handleDuplicateForm() {
    setFormProduto({
      ...formProduto,
      cod_gtin_principal: formProdutoBlank.cod_gtin_principal,
      Gtins: formProdutoBlank.Gtins,
    });
    const cod_similar = getValues('cod_similar');
    if (formProduto.Fornecedores.fornecedores.length > 0) {
      await MySwal.fire({
        title: 'Confirmação',
        text: 'Produto a ser duplicado possui fornecedores relacionados. Deseja também duplicá-los?',
        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (!result.isConfirmed) handleResetForms();
        else {
          setControlaLimpaForm(true);
          await delay(200);
          handleClearProdutoFornecedor();
        }
      });
    } else handleResetForms();

    setInitInicializado(false);
    setValue('embalagens', formProdutoBlank.Embalagens);
    setValue('cod_similar', cod_similar);
    setTimeout(handleSimilar, 500);
  }

  function handleChangeIsDuplicate(value: boolean) {
    setIsDuplicate(value);
  }

  function handleChangeIsDuplicateForUpdate(value: boolean) {
    setIsDuplicateForUpdate(value);
  }
  const changeFormAssociado = useCallback((value: FormAssociado) => {
    setFormAssociado(value);
  }, []);

  function changeFormProduto(value: FormProduto) {
    setFormProduto(value);
  }
  function changeFormProdutoLoja(value: FormProdutoLoja) {
    setFormProdutoLoja(value);
  }

  function toggleInitInicitalizado(value: boolean) {
    setInitInicializado(value);
  }

  function toggleIsUpdate(value: boolean) {
    setIsUpdate(value);
  }

  function toggleIsShowSearch(value: boolean) {
    setIsShowSearch(value);
  }

  const handleChangeInput = (valores: { entrada: string; saida: string }) => {
    const entradaFiltrada = tipoPisCofinsEntrada.find((item: Select) => {
      return Number(item.value) === Number(valores.entrada);
    });
    const saidaFiltrada = tipoPisCofinsSaida.find((item: Select) => {
      return Number(item.value) === Number(valores.saida);
    });
    setValue('cst_entrada', entradaFiltrada);
    setValue('cst_saida', saidaFiltrada);
  };

  const mapeamentoValoresCst: MapeamentoValores = {
    '-1': {
      entrada: loja.cst_pis_cof_entrada,
      saida: loja.cst_pis_cof_saida,
    },
    '0': {
      entrada: loja.cst_pis_cof_ent_aliq_zero,
      saida: loja.cst_pis_cof_saida_aliq_zero,
    },
    '1': {
      entrada: loja.cst_pis_cof_ent_monof,
      saida: loja.cst_pis_cof_saida_monof,
    },
    '2': {
      entrada: loja.cst_pis_cof_ent_subst,
      saida: loja.cst_pis_cof_saida_subst,
    },
    '3': {
      entrada: loja.cst_pis_cof_ent_imune,
      saida: loja.cst_pis_cof_saida_imune,
    },
    '4': {
      entrada: loja.cst_pis_cof_ent_susp,
      saida: loja.cst_pis_cof_saida_susp,
    },
    '5': {
      entrada: loja.cst_pis_cof_ent_monof,
      saida: loja.cst_pis_cof_saida,
    },
  };

  const handleChangeValoresCst = (
    tipo: keyof MapeamentoValores,
  ): { entrada: string; saida: string } | null => {
    if (tipo in mapeamentoValoresCst) {
      const valores = mapeamentoValoresCst[tipo];
      handleChangeInput(valores);
    }
    return null;
  };

  const desabilitarBuscaModal = () => {
    const { flg_nao_pis_cofins, tipo_nao_pis_cofins } = getValues();
    if (!flg_nao_pis_cofins || tipo_nao_pis_cofins === undefined) {
      return true;
    }
    return false;
  };

  const handleChangeEnabledModal = () => {
    setEnableModalSped((prev: boolean) => {
      return !prev;
    });
  };

  async function handleTipoIPI(value: number | string) {
    if (value === -1) setIpiHabilitar(true);
    else setIpiHabilitar(false);
  }

  async function getUnidades() {
    const res = await api.get('/unidade');
    const { data, success } = res.data;
    if (success) {
      const selectOptions = data.map((unidade: any) => {
        return {
          value: unidade.des_unidade,
          label: unidade.des_unidade,
        };
      });

      setUnidadesOptions(selectOptions);
    }
  }

  const removeEditNaoPisCofinsCache = () => {
    queryClient.removeQueries(`edit_nao_pis_cofins_${28}`);
  };

  const getIdContabeis = async () => {
    const { data } = await api.get(`/perfil-documento-id-contabil`);
    if (data.success) {
      const newIdContabeis = data.data
        .map((c: any) => ({
          label: `${c.cod_id_ctb} - ${c.des_id_ctb}`,
          value: c.cod_id_ctb,
        }))
        .sort((a: any, b: any) => a.value - b.value);

      setIdContabeis(newIdContabeis);
      setValue('id_contabeis_options', newIdContabeis);
    }
  };

  const getTipoItem = useCallback(async () => {
    const { data } = await api.get('/tipo_item');
    if (data.success) {
      const options = data.data
        .map((tipo: ITipoItem) => {
          return {
            label: `${tipo.num_tipo_item} - ${tipo.des_tipo_item}`,
            value: tipo.cod_tipo_item,
          };
        })
        .sort(function (x: any, y: any) {
          return x.value - y.value;
        });
      setTipoItem(options);
      setValue('tipo_itens', options);
    }
  }, []);

  return (
    <ProdutoContext.Provider
      value={{
        formProduto,
        changeFormProduto,
        formProdutoLoja,
        changeFormProdutoLoja,
        isUpdate,
        handleClearForms,
        toggleIsUpdate,
        initInicializado,
        toggleInitInicitalizado,
        isShowSearch,
        toggleIsShowSearch,
        handleSelectedLoja,
        selectedLoja,
        handleDuplicateForm,
        mainLoja,
        changeMainLoja,
        formAssociado,
        changeFormAssociado,
        tipoGtin,
        setTipoGtin,
        formGtin,
        setFormGtin,
        produtoFornecedoresUns,
        setProdutoFornecedorUns,
        selectedCodFornecedor,
        setSelectedCodFornecedor,
        filtro,
        setFiltro,
        register,
        control,
        reset,
        getValues,
        setValue,
        formState,
        setError,
        clearErrors,
        clearFields,
        handleResetClearField,
        isDuplicate,
        handleChangeIsDuplicate,
        watch,
        handleChangeIsDuplicateForUpdate,
        isDuplicateForUpdate,
        handleSubmit,
        setTributacoesEntrada,
        setTributacoesSaida,
        tributacoesEntrada,
        tributacoesSaida,
        ncmIsFromTheProduct,
        setNcmIsFromTheProduct,
        handleChangeValoresCst,
        desabilitarBuscaModal,
        enableModalSped,
        setEnableModalSped,
        handleChangeEnabledModal,
        refSaida,
        optionsEntradaPisCofins,
        setOptionsEntradaPisCofins,
        handleTipoIPI,
        ipiHabilitar,
        setIpiHabilitar,
        tabGeral,
        setTabGeral,
        watchFlgVedado,
        resetFormEmbalagem,
        tab,
        setTab,
        initFormFornecedor,
        setInitFormFornecedor,
        initFormFornecedorUn,
        setInitFormFornecedorUn,
        produtoFornecedores,
        setProdutoFornecedores,
        formProdFornUN,
        setFormProdFornUN,
        formProdutoFornecedor,
        setFormProdutoFornecedor,
        idProdUnid,
        setIdProdUnid,
        handleClearProdFornUN,
        handleClearProdutoFornecedor,

        associadosPai,
        setAssociadosPai,
        associadosFilho,
        setAssociadosFilho,
        watchTipoAssociado,
        similares,
        setSimilares,
        watchFlgNaoPisCofins,
        setHasErroTipoIncidente,
        hasErroTipoIncidente,
        removeEditNaoPisCofinsCache,
        flgFabricante,
        setFlgFabricante,
        disabledField,
        setDisabledField,
        setFormProdutoFornecedorEmbalagem,
        formProdutoFornecedorEmbalagem,

        tipoItem,
        setTipoItem,
        idContabeis,
        setIdContabeis,
        handleTipoItemAndIdCtb,
        getDepartamentos,
        departamentos,
        setDepartamentos,
        getIdContabeis,
        getTipoItem,
        getUnidades,
        unidadesOptions,
        resetSimilar,
        setResetSimilar,
      }}
    >
      {children}
    </ProdutoContext.Provider>
  );
}

export const useProduto = () => {
  return useContext(ProdutoContext);
};
